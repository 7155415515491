import React from 'react';
import classes from './spinner.module.scss';
import { CircularProgress, CircularProgressProps } from '@mui/material';

interface ProgressSpinnerProps extends CircularProgressProps {
  color?:
    | 'secondary'
    | 'primary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'inherit';
}

const ProgressSpinner: React.FC<ProgressSpinnerProps> = ({
  color,
  ...props
}) => {
  return (
    <div className={classes.loading}>
      <CircularProgress color={color} {...props} />
    </div>
  );
};

export default ProgressSpinner;
