import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

interface IViewport {
  width: number | undefined;
  height: number | undefined;
  isViewPortLoaded: boolean;
}

const ViewportContext = createContext<IViewport>({
  width: undefined,
  height: undefined,
  isViewPortLoaded: false,
});

export const ViewportProvider: FC = ({ children }) => {
  const [width, setWidth] = React.useState<number | undefined>(undefined);
  const [height, setHeight] = React.useState<number | undefined>(undefined);
  const [isViewPortLoaded, setisViewPortLoaded] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
        setisViewPortLoaded(true);
        return <p>loading</p>;
      };

      window.addEventListener('resize', handleResize);
      handleResize();
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return (
    <ViewportContext.Provider value={{ width, height, isViewPortLoaded }}>
      {children}
    </ViewportContext.Provider>
  );
};

export function useViewport(): IViewport {
  return useContext<IViewport>(ViewportContext);
}
