import ProgressSpinner from '@components/shared/spinner';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import ThemeProvider from '@mui/styles/ThemeProvider';
// import { SentryCommonsProvider } from '@store/SentryCommons';
import { SnackbarProvider } from '@store/Snackbar';
import { ViewportProvider } from '@store/ViewPort';
import { pageview } from '@utils/GTM/gtm';
import theme from '@utils/theme';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import React, { useEffect } from 'react';
import '../styles/_base.scss';

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  // const MATOMO_URL = process.env.NEXT_PUBLIC_MATOMO_URL;
  // const MATOMO_SITE_ID = process.env.NEXT_PUBLIC_MATOMO_SITE_ID;
  // const [load, setLoad] = React.useState(false);
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
    // if (process.env.NODE_ENV == 'production' && MATOMO_URL && MATOMO_SITE_ID) {
    //   init({ url: MATOMO_URL, siteId: MATOMO_SITE_ID });
    // }
  }, []);
  const router = useRouter();
  console.log(router.pathname);
  useEffect(() => {
    router.events.on('routeChangeComplete', pageview);
    return () => {
      router.events.off('routeChangeComplete', pageview);
    };
  }, [router.events]);
  return (
    <>
      <Head>
        <title>Wellproz</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      {/* GTM */}
      <Script
        id="gtag"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-MLP53DG');`,
        }}
      />

      {router.pathname !== '/test-template' ? (
        <Script
          src={`https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA}`}
          strategy="beforeInteractive"
        />
      ) : (
        ''
      )}
      <Script
        id="help-scout"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `!function (e, t, n) { function a() { var e = t.getElementsByTagName("script")[0], n = t.createElement("script"); n.type = "text/javascript", n.async = !0, n.src = "https://beacon-v2.helpscout.net", e.parentNode.insertBefore(n, e) } if (e.Beacon = n = function (t, n, a) { e.Beacon.readyQueue.push({ method: t, options: n, data: a }) }, n.readyQueue = [], "complete" === t.readyState) return a(); e.attachEvent ? e.attachEvent("onload", a) : e.addEventListener("load", a, !1) }(window, document, window.Beacon || function () { });`,
        }}
      />
      <Script
        id="help-scout-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `window.Beacon('init', '3882e7dd-eadd-414b-8692-ae23e1d77fbc');
          window.Beacon('config', {hideFABLabelOnMobile:true} );`,
        }}
      />
      <Script
        src="https://staxjs.staxpayments.com/stax.js?nocache=2"
        async
        strategy="afterInteractive"
        onLoad={() => {
          // setLoad(true);
          console.log('loaded ,stax');
        }}
      />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ViewportProvider>
            <SnackbarProvider>
              {/* <SentryCommonsProvider>
                <GlobalsProvider> */}
              <Component {...pageProps} />
              {/* </GlobalsProvider>
              </SentryCommonsProvider> */}
            </SnackbarProvider>
          </ViewportProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default dynamic(() => Promise.resolve(MyApp), {
  loading: () => <ProgressSpinner />,
});
