// This file configures the initialization of Sentry on the server.
// The config you add here will be used whenever the server handles a request.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
const isProd = process.env.NODE_ENV === 'production';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  ignoreErrors: [
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
  ],
  beforeSend(event) {
    // Check if it is an exception, and if so, show the report dialog
    // if(event.exception.values[0].type==="")
    if (event.exception) {
      console.log(event.exception);
      Sentry.showReportDialog({
        eventId: event.event_id,
      });
    }
    return event;
  },
  // ignoreErrors: [
  //   "Non-Error exception captured",
  //   "Non-Error promise rejection captured"
  // ],
  // maxBreadcrumbs: 50,
  // debug: true,
  // Adjust this value in production, or use tracesSampler for greater control
  environment: process.env.NEXT_PUBLIC_CHECK_ENV,
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});