import { Alert, Snackbar, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    snackBar: {
      zIndex: 1501,
    },
    alert: {
      fontSize: '1.4rem',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.2rem',
      },
    },
  })
);

type ISnackbarState = {
  open?: boolean;
  severity: 'success' | 'warning' | 'error' | 'info';
  message: string;
};

type ISnackbarContext = {
  SnackbarSuccess: (message: string) => void;
  SnackbarError: (message: string) => void;
  SnackbarWarning: (message: string) => void;
  SnackbarInfo: (message: string) => void;
};

const SnackbarContext = React.createContext<ISnackbarContext>({
  SnackbarSuccess: () => {},
  SnackbarError: () => {},
  SnackbarWarning: () => {},
  SnackbarInfo: () => {},
});

export function useSnackbar(): ISnackbarContext {
  return useContext(SnackbarContext);
}

const SnackbarProvider: React.FC = ({ children }) => {
  const [snackbarState, setSnackbarState] = React.useState<ISnackbarState>({
    open: false,
    severity: 'success',
    message: 'Hello, world!',
  });

  const handleClose = () => {
    setSnackbarState((prev) => ({ ...prev, open: false }));
  };

  const SnackbarSuccess = (message: string) => {
    setSnackbarState({
      open: true,
      severity: 'success',
      message: message,
    });
  };

  const SnackbarError = (message: string) => {
    setSnackbarState({
      open: true,
      severity: 'error',
      message: message,
    });
  };

  const SnackbarWarning = (message: string) => {
    setSnackbarState({
      open: true,
      severity: 'warning',
      message: message,
    });
  };

  const SnackbarInfo = (message: string) => {
    setSnackbarState({
      open: true,
      severity: 'info',
      message: message,
    });
  };

  const muiStyles = useStyles();
  return (
    <SnackbarContext.Provider
      value={{ SnackbarSuccess, SnackbarError, SnackbarWarning, SnackbarInfo }}
    >
      {children}

      <Snackbar
        open={snackbarState.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleClose}
        key={'top' + 'right'}
        classes={{ root: muiStyles.snackBar }}
      >
        <Alert
          className={muiStyles.alert}
          onClose={handleClose}
          elevation={2}
          severity={snackbarState.severity}
        >
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export { SnackbarContext, SnackbarProvider };
